/* About.css */

.about {
    
    height: 120vh; 
  
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
   
    background-color: #ffffff;
    
    background-size: cover;
    background-position: center;
  
   
    padding: 20px;
   
  
    
  }
  