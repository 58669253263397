@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Schoolbell&display=swap');

.school-bell-text{
  font-family: 'Schoolbell', cursive;
  }


.width-100{
  width: 100%;
}
.montserrat-text-100{
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

.montserrat-text-200{
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

.montserrat-text-300{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.montserrat-text-400{
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.montserrat-text-500{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.brown-bg{
background: linear-gradient(to right, #fffdf9, #ebd9c3);
}

.brown-bg-b{
  background: linear-gradient(to bottom, #fffdf9, #ebd9c3);
  }

  .dark-brown-bg{
    background: linear-gradient(to right, #fff6de, #b98054);
    }


.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 1s, transform 1s;
}

.text-container.show {
  opacity: 1;
  transform: scale(1);
}

.program-bg{
  background-image: url('../public/program-bg1.svg');
  background-size: cover; 
  background-position: center center; 
  background-repeat: no-repeat;
}

.testimonials-bg{
  background-image: url('../public/testimonials-bg.gif');
  background-size: cover; 
  background-position: center center; 
  background-repeat: no-repeat;
}

