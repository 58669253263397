/* CSS for Typing Animation */
@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  
  .typing-animation::after {
    content: "1234567890"; 
    animation: typing 3s steps(10, end) infinite; 
  }
  