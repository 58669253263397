.heading-text{
font-family: 'Schoolbell', cursive;
}
.header{
    height: 80%;
}
@media (max-width:600px){
    .header-text{
margin-top: 2rem;

    }
}