.circle-card {
   
    width: 250px;
    height: 250px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    color: #fff;
    text-align: center;
  }

.toddler-session{
  background-image: linear-gradient(to top, #edac07, #edac0756);
}

.playgroup{
    background-image: linear-gradient(to top, #eb2d62, #eb2d6357);
  }

  .nursery{
    background-image: linear-gradient(to top, #5d990f, #65c50c56);
  }

  .junior-kg{
    background-image: linear-gradient(to top, #00b0b3, #00b0b352);
  }

  .senior-kg{
    background-image: linear-gradient(to top, #045ce991, #09c5f998);
  }
  
  @keyframes popUpAnimation {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pop-up {
    animation: popUpAnimation 0.5s ease-in-out;
  }


  

.program {
    
  height:650vh;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
   
    background-color: #ffffff;
    
    background-size: cover;
    background-position: center;
  
   
    padding: 20px;
    
  }
  
 
@media (max-width: 750px) {
    .grid-cols-3 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
  
    .circle-card {
      width: 100%; 
      margin: 0 0 1rem; 
    }
  }
  
 
  @media (max-width: 500px) {
    .circle-card {
      border-radius: 50px; 
      margin: 0 0 1rem; 
    }
    
  }
  

  
  
    