.mission-bg-image {
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
  }

  .peach-bg{
    background-color: #FFFCF8;
    border-radius: 44%;
    opacity: 98%;
  }



  
  